<script setup lang="ts">
  const props = defineProps<{
    starRating: number | null | undefined
    starClass?: string
    showNumber?: boolean
    tooltip?: {
      id: string
      title: string
      body: string
    }
  }>()

  const { $root } = useNuxtApp()

  const hasTooltip = computed(() => !!props.tooltip)

  const onClickStarRating = () => {
    if (!hasTooltip) return
    $root.emit(`trigger:${makeRootToolTipEvent('show', props.tooltip.id)}`)
  }
</script>

<template>
  <div
    :class="['star-rating', { 'with-tooltip': hasTooltip }]"
    @click.prevent="onClickStarRating"
    v-if="!_isNil(starRating) && starRating !== 0"
  >
    <HpIcon
      v-for="_ in _range(0, _floor(starRating ?? 0))"
      name="star-filled"
      :class="starClass ?? 'text-[#ffc107]'"
    />
    <HpIcon v-if="starRating > _floor(starRating ?? 0)" name="star-half" class="text-[#ffc107]" />
    <HpIcon v-for="_ in _range(_ceil(starRating) + 1, 6)" name="star" class="text-[#ffc107]" />
    <span v-if="showNumber" class="px-2 text-xl text-primary-900">{{ starRating.toFixed(1) }}</span>
  </div>
  <div v-else>Plan too new to have Star Ratings</div>
  <HpToolTip v-if="hasTooltip" :id="tooltip.id" :title="tooltip?.title">
    <template #open-button>&nbsp;</template>
    <span v-html="tooltip?.body" />
  </HpToolTip>
</template>

<style scoped>
  .star-rating {
    @apply flex items-center -space-x-1;

    &.with-tooltip {
      @apply cursor-pointer border-b-2 border-dashed border-border;
    }

    :deep(svg) {
      @apply w-8;
    }
  }
</style>
